<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  src="@/assets/images/logo/goskate_logo_hor_blue.png"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <div>
      <b-overlay class="overlay-header-info" :show="isLoading" spinner-small spinner-variant="primary">
        <div class="user-image-sidebar">
          <b-avatar
            size="100px"
            :src="'data:image/jpeg;base64,' + userInfo.avatar"
            button
            @click="$refs.refInputEl.$el.click()"
          />
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="handleImage"
          />
          <!-- <q-img
            v-for="(data, index) in base64data"
            :key="index"
            :src="'data:image/jpeg;base64,' + data"

            :src="require('@/assets/images/portrait/small/supportFeedBackIcon.png')"

            ratio="1"
          /> -->
        </div>
        <div class="user-text-sidebar">
          <p>{{ userInfo.full_name }}</p>
        </div>
        <div class="user-raiting-sidebar">
          <b-form-rating
            id="rating-inline"
            no-border
            readonly
            inline
            show-value
            precision="1"
            variant="warning"
            locale="en-US"
            :value="userInfo.rating"
          />
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div
          :class="{'d-block': shallShadowBottom}"
          class="shadow-bottom"
        />
        <div class="container-vue-profile-button">
          <b-button 
            class="vue-profile-button"
            variant="warning"
            :href="'https://pro.goskate.com/instructor?code=' + userInfo.code"
          >
            See your Page
          </b-button>
        </div>
      </b-overlay>
      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <vertical-nav-menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
    </div>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, BImg, BFormRating, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar,
    BFormRating,
  },

  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      userInfo: {
        rating: 0,
        full_name: null,
      },
    }
  },

  beforeCreate() {
    this.isLoading = true; // Устанавливаем флаг загрузки в true перед запросом
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        this.userInfo = res.data;
        this.isLoading = false; // Устанавливаем флаг загрузки в false после загрузки данных
      })
      .catch(err => {
        console.error(err);
        this.isLoading = false; // В случае ошибки также устанавливаем флаг загрузки в false
      });
  },

  methods: {
    handleImage(e) {
      const inputImageRenderer = e.target.files[0]
      if (!inputImageRenderer) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'success',
            text: 'No file chosen',
          },
        })
        return
      }
      if (inputImageRenderer.size > 800 * 1024) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'danger',
            text: 'File too big (> 800KB)',
          },
        })
        return
      }

      this.createBase64Image(inputImageRenderer)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CompassIcon',
          variant: 'success',
          text: 'File uploaded',
        },
      })
    },
    createBase64Image(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = /,(.+)/.exec(reader.result)[1]
        // console.log(`file to base64 result: ${base64String}`)
        if (base64String) {
          this.$http.post('/api/profile/upload_instructor_avatar', base64String)
        }
      }
      // reader.onerror = error => {
      // console.log('Error: ', error)
      // }
    },
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.vue-profile-button{
  margin: 0 15px;
}

.container-vue-profile-button{
  display: flex;
  justify-content: center
}

</style>
