<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © GOSKATE All rights Reserved
    </span>
  </p>
</template>

<script>

export default {
  components: {
  },
}
</script>
