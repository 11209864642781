<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.first_name }}
        </p>
      </div>
      <b-avatar
        size="40px"
        :src="'data:image/jpeg;base64,' + userInfo.avatar"
      />
    </template>

    <b-dropdown-item
      :to="{ name: 'dashboard-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'dashboard-lessons' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BookOpenIcon"
        class="mr-50"
      />
      <span>Lessons</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'dashboard-invitations' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="InboxIcon"
        class="mr-50"
      />
      <span>Invitations</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'dashboard-payment' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Payments</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userInfo: {
        rating: 0,
        full_name: null,
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },

  beforeCreate() {
    // ? console.log('data')
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.userInfo = res.data
      })
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('code')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
